import React from 'react';
import PropTypes from 'prop-types';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import './conquest-spotlight-native-ad.scss';

export function ConquestSpotlightNativeAd(props) {
  const { mobile, isEditorial, ...restProps } = props;

  const mobileAdName = isEditorial ? 'mmrect' : 'mspot';
  const mobileNativeStyle = isEditorial ? 'none' : 'enhancedspot';
  const mobileWrapperClass = isEditorial ? 'mrect' : 'conquest-spotlight-native mspot';
  if (mobile) {
    return (
      <NativeAd
        adNameMobile={mobileAdName}
        nativeStyle={mobileNativeStyle}
        wrapperClass={`${mobileWrapperClass}m-auto`}
        mobileOnly
        showLabel
        verticalAlign="center"
        {...restProps}
      />
    );
  }
  return (
    <div className="conquest-spotlight-native">
      <NativeAd
        adNameWired="spot"
        nativeStyle="enhancedspot"
        wrapperClass="spot"
        wiredBreakpoints={{
          lg: true,
          xl: true,
        }}
        wiredOnly
        showLabel
        verticalAlign="center"
        {...restProps}
      />
    </div>
  );
}

ConquestSpotlightNativeAd.propTypes = {
  mobile: PropTypes.bool,
  noRefresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  isEditorial: PropTypes.bool,
};

ConquestSpotlightNativeAd.defaultProps = {
  mobile: false,
  noRefresh: false,
  isEditorial: false,
};
