import React from 'react';
import PropTypes from 'prop-types';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { Image } from 'site-modules/shared/components/image/image';
import { Link } from 'site-modules/shared/components/link/link';

import './image-card.scss';

export function ImageCard({ content, isVisible, enableMarketBrewTestBestType }) {
  const { title, 'media-image': image, 'link-url': href } = content.getAllMetadata();
  const displayTitle = enableMarketBrewTestBestType ? `Best ${title}` : title;
  return (
    <Link
      disabled={!isVisible}
      to={href}
      className="image-card d-block rounded d-flex flex-column justify-content-center align-items-center"
    >
      <Image imageId={image} source="" alt="" className="h-100" />
      <h3 className="article-title size-24 font-weight-bold text-white text-center">{displayTitle}</h3>
    </Link>
  );
}

ImageCard.propTypes = {
  content: CmsEntities.Content,
  isVisible: PropTypes.bool,
  enableMarketBrewTestBestType: PropTypes.bool,
};

ImageCard.defaultProps = {
  content: DEFAULT_CONTENT,
  isVisible: false,
  enableMarketBrewTestBestType: false,
};
