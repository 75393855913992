import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Row, Col } from 'reactstrap';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { PodcastPreview } from 'site-modules/shared/components/podcast-preview/podcast-preview';

import './podcast-card.scss';

export function PodcastCard({
  content,
  openIframeModal,
  isVisible,
  isInlineCard,
  isTopSection,
  isLazyLoadImages,
  isMobile,
}) {
  const { 'podcast-url': iframeCode, title, 'article-date': articleDate } = content.getAllMetadata();
  const image = content
    .metadata('media-image')
    .value('')
    .replace('300.jpg', isMobile ? '300.jpg' : '717.jpg');

  return (
    <Row className="podcast-card car-news-card">
      <Col xs={isInlineCard ? 5 : 12}>
        <PodcastPreview
          disabled={!isVisible}
          iframeCode={iframeCode}
          title={title}
          image={image}
          openIframeModal={openIframeModal}
          imgClassName="img-fluid card-image w-100 rounded"
          isLazyLoadImages={isLazyLoadImages}
        />
      </Col>
      <Col xs={isInlineCard ? 7 : 12} className={classnames('article-info', { 'mt-1': !isInlineCard })}>
        {isTopSection && <div className="size-12 text-uppercase font-weight-bold text-primary mb-0_5">Podcast</div>}
        <h3 className="podcast-title size-16 text-gray-darker">
          <ContentFragment>{title}</ContentFragment>
        </h3>
        {isTopSection && <span className="size-12 text-gray-dark">{articleDate}</span>}
      </Col>
    </Row>
  );
}

PodcastCard.propTypes = {
  content: CmsEntities.Content,
  openIframeModal: PropTypes.func,
  isVisible: PropTypes.bool,
  isInlineCard: PropTypes.bool,
  isTopSection: PropTypes.bool,
  isLazyLoadImages: PropTypes.bool,
  isMobile: PropTypes.bool,
};

PodcastCard.defaultProps = {
  content: DEFAULT_CONTENT,
  openIframeModal: noop,
  isVisible: true,
  isInlineCard: false,
  isTopSection: false,
  isLazyLoadImages: false,
  aspectRatio: '16:9',
  isMobile: false,
};
