import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { flatten, get } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { NewsCard } from 'site-modules/editorial/components/car-news/news-card/news-card';
import { InlineNewsCard } from 'site-modules/editorial/components/car-news/inline-news-card/inline-news-card';
import { VideoCard } from 'site-modules/editorial/components/car-news/video-card/video-card';
import { PodcastCard } from 'site-modules/editorial/components/car-news/podcast-card/podcast-card';
import { AdEntry } from 'site-modules/editorial/components/ad-entry/ad-entry';

import './car-news-articles-grid.scss';

export const CARDS_TYPE_MAP = {
  default: 'default',
  inline: 'inline',
};

const CARDS_COMPONENT_MAP = {
  [CARDS_TYPE_MAP.default]: NewsCard,
  [CARDS_TYPE_MAP.inline]: InlineNewsCard,
};

const DEFAULT_LAYOUT = [[CARDS_TYPE_MAP.default]];

const LAYOUTS_MAP_DESKTOP = {
  'top-stories': [[CARDS_TYPE_MAP.default, 1], [CARDS_TYPE_MAP.inline]],
  'car-news': [[CARDS_TYPE_MAP.default]],
  'featured-review': [[CARDS_TYPE_MAP.inline]],
  'ev-widget': [[CARDS_TYPE_MAP.inline]],
  'ev-reviews': [[CARDS_TYPE_MAP.default, 6]],
};

const LAYOUTS_MAP_MOBILE = {
  'top-stories': [[CARDS_TYPE_MAP.default, 1], [CARDS_TYPE_MAP.inline]],
  'car-news': [[CARDS_TYPE_MAP.inline]],
  'featured-review': [[CARDS_TYPE_MAP.inline]],
  'ev-widget': [[CARDS_TYPE_MAP.inline]],
  'ev-reviews': [[CARDS_TYPE_MAP.inline, 6]],
  'helpful-articles': [[CARDS_TYPE_MAP.inline]],
};

const VISIBLE_BEFORE_COLLAPSE_DESKTOP = {
  'car-news': 7,
};

const VISIBLE_BEFORE_COLLAPSE_MOBILE = {
  'car-news': 4,
};

export function CarNewsArticlesGrid({ className, content, totalCardsLimit, isMobile, forceLayout, ...props }) {
  const { id } = content;
  const layout = forceLayout || (isMobile ? LAYOUTS_MAP_MOBILE[id] : LAYOUTS_MAP_DESKTOP[id]) || DEFAULT_LAYOUT;
  const cardsEntries = content.children().slice(0, totalCardsLimit);
  const cardsSections = [];
  const isTopSection = id === 'top-stories';

  layout.forEach(([type, limit], index) => {
    const prevLayoutSection = layout[index - 1];
    const prevLayoutSectionLimit = get(prevLayoutSection, [1], 0);
    cardsSections.push(
      cardsEntries.slice(prevLayoutSectionLimit, limit).map(entry => {
        let Component = CARDS_COMPONENT_MAP[type];
        const isVideoEntry = entry.hasMetadata('videoId');
        const isPodcastEntry = entry.hasMetadata('podcast-url');
        const isAdEntry = entry.hasMetadata('adType');
        const isInlineCard = type === CARDS_TYPE_MAP.inline;
        const isFirstCard = index === 0;

        if (isAdEntry) {
          return <AdEntry key={entry.id} entry={entry} isMobile={isMobile} />;
        }

        if (isVideoEntry) {
          Component = VideoCard;
        } else if (isPodcastEntry) {
          Component = PodcastCard;
        }

        return (
          <Component
            content={entry}
            isMobile={isMobile}
            key={entry.id}
            isInlineCard={isInlineCard}
            isTopSection={isTopSection}
            {...isTopSection && !isMobile && { aspectRatio: isFirstCard ? '16:7' : '3:2' }}
            {...props}
          />
        );
      })
    );
  });

  const cards = flatten(cardsSections);
  const cardsBeforeCollapseNum = isMobile ? VISIBLE_BEFORE_COLLAPSE_MOBILE[id] : VISIBLE_BEFORE_COLLAPSE_DESKTOP[id];
  const cardsBeforeCollapse = cards.slice(0, cardsBeforeCollapseNum);
  const cardsCollapsed = cards.slice(cardsBeforeCollapseNum);

  return cardsBeforeCollapseNum && cardsCollapsed.length > 0 ? (
    <div className={className}>
      <div className={classnames('car-news-articles-grid before-collapse mb-1', id)}>{cardsBeforeCollapse}</div>
      <ContentCollapse
        textClosed="See more"
        textOpen="See less"
        justify="justify-content-start"
        btnContainerClasses="m-0 p-0"
        btnClasses="p-0 size-16 text-primary-darker more-info-btn"
        btnTextClasses="font-weight-normal"
        showBtnBelow
        isEclipseFade={false}
        isOpenOnLoad={false}
      >
        <div className={classnames('car-news-articles-grid mb-1', id)}>{cardsCollapsed}</div>
      </ContentCollapse>
    </div>
  ) : (
    <div className={classnames('car-news-articles-grid', className, id)}>{cards}</div>
  );
}

CarNewsArticlesGrid.propTypes = {
  isMobile: PropTypes.bool,
  content: CmsEntities.Content,
  totalCardsLimit: PropTypes.number,
  className: PropTypes.string,
  forceLayout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
};

CarNewsArticlesGrid.defaultProps = {
  isMobile: false,
  content: DEFAULT_CONTENT,
  totalCardsLimit: undefined,
  className: undefined,
  forceLayout: null,
};
