import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { getFullImageUrl } from 'client/utils/image-helpers';
import { Link } from 'site-modules/shared/components/link/link';
import { VideoPreview } from 'site-modules/shared/components/video-preview/video-preview';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { TopRatedRibbon } from 'site-modules/shared/components/top-rated-ribbon/top-rated-ribbon';

import './video-card.scss';

export function VideoCard({ content, openVideoModal, isVisible, isInlineCard, isTopSection, isLazyLoadImages }) {
  const { videoId, thumbnailURL, videoTitle, anchorURL, uploadDate } = content.getAllMetadata();
  const hasTopRatedBadge = content.metadata('topRatedBadge').boolean();

  const openVideo = useCallback(() => {
    openVideoModal({
      creativeId: 'car_news_industry_article_index',
      videoId,
      videoTitle,
      videoAspectRatio: '16:9',
    });
  }, [openVideoModal, videoId, videoTitle]);

  const TitleTag = anchorURL ? Link : 'button';
  const titleProps = anchorURL
    ? { to: anchorURL }
    : {
        className: 'title-btn text-left bg-transparent p-0 border-0',
        onClick: openVideo,
        tabIndex: '-1',
      };

  return (
    <Row className="video-card car-news-card">
      <Col xs={isInlineCard ? 5 : 12} className="pos-r">
        <VideoPreview
          videoId={videoId}
          openVideoModal={openVideoModal}
          videoThumbnailUrl={thumbnailURL ? getFullImageUrl(thumbnailURL, 600) : null}
          videoTitle={videoTitle}
          creativeId="car_news_industry_article_index"
          isStaticPos
          isAutoHeight
          isWhiteBtn
          disabled={!isVisible}
          imgClassName="img-fluid card-image w-100 rounded"
          isLazyLoadImages={isLazyLoadImages}
        />
        {hasTopRatedBadge && <TopRatedRibbon color="blue" style={{ position: 'absolute', top: '12px', left: '8px' }} />}
      </Col>
      <Col xs={isInlineCard ? 7 : 12} className={classnames('article-info', { 'mt-1': !isInlineCard })}>
        {isTopSection && <div className="size-12 text-uppercase font-weight-bold text-primary mb-0_5">Watch video</div>}
        <TitleTag {...titleProps} disabled={!isVisible}>
          <h3 className="video-title heading-5">
            <ContentFragment>{videoTitle}</ContentFragment>
          </h3>
        </TitleTag>
        {isTopSection && <span className="size-12 text-gray-dark">{uploadDate}</span>}
      </Col>
    </Row>
  );
}

VideoCard.propTypes = {
  content: CmsEntities.Content,
  openVideoModal: PropTypes.func,
  isVisible: PropTypes.bool,
  isInlineCard: PropTypes.bool,
  isTopSection: PropTypes.bool,
  isLazyLoadImages: PropTypes.bool,
};

VideoCard.defaultProps = {
  content: DEFAULT_CONTENT,
  openVideoModal: noop,
  isVisible: true,
  isInlineCard: false,
  isTopSection: false,
  isLazyLoadImages: false,
};
