import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { useOnScreen } from 'client/site-modules/shared/hooks/use-on-screen';
import { Carousel } from 'client/site-modules/shared/components/carousel/carousel';
import { Link } from 'site-modules/shared/components/link/link';
import { AdEntry } from 'site-modules/editorial/components/ad-entry/ad-entry';

import './cards-carousel.scss';

function renderComponent(entry, CardComponent, otherProps) {
  const RenderedComponent = entry.hasMetadata('adType') ? AdEntry : CardComponent;
  return <RenderedComponent entry={entry} content={entry} {...otherProps} />;
}

// eslint-disable-next-line react/prop-types
export function CardsCarouselSlide({ entry, CardComponent, ...props }) {
  const ref = useRef();
  // We set rootMargin here to track the horizontal visibility only
  const [isVisible] = useOnScreen(ref, { threshold: 0.5, rootMargin: '4000px 0px 4000px 0px' });

  return (
    <div ref={ref} className="cards-carousel-slide col">
      {renderComponent(entry, CardComponent, { ...props, isVisible })}
    </div>
  );
}

export function CardsCarousel({
  content,
  CardComponent,
  childrenFilter,
  slidesToShowDesktop,
  slidesToShowMobile,
  slidesToScrollDesktop,
  slidesToScrollMobile,
  forceCarousel,
  showArrows,
  centerMode,
  infinite,
  initialSlide,
  isMobile,
  ...props
}) {
  let cardsEntries = content.children();
  if (childrenFilter) {
    cardsEntries = cardsEntries.filter(child => childrenFilter(child, { isMobile }));
  }
  const isOneSlideToShowOnMobile = slidesToShowMobile === 1;
  const slidesToShow = isMobile ? slidesToShowMobile : slidesToShowDesktop;

  const moreLinkText = content.metadata('moreLinkText').value();
  const moreLink = content.metadata('moreLink').value();

  const defaultInitialSlide = isMobile && !isOneSlideToShowOnMobile ? slidesToShowMobile - 1 : 0;

  return (
    <Fragment>
      {slidesToShow >= cardsEntries.length && !forceCarousel ? (
        <div className="cards-flex row flex-nowrap">
          {cardsEntries.map(entry => (
            <div key={entry.id} className="col">
              {renderComponent(entry, CardComponent, props)}
            </div>
          ))}
        </div>
      ) : (
        <Carousel
          swipe
          arrows={showArrows}
          infinite={infinite}
          slidesToShow={slidesToShowDesktop}
          slidesToScroll={slidesToScrollDesktop || slidesToShowDesktop}
          centerMode={centerMode}
          centerPadding="50% 0 0"
          initialSlide={initialSlide !== null ? initialSlide : defaultInitialSlide}
          responsive={[
            {
              breakpoint: 480,
              settings: {
                slidesToShow: slidesToShowMobile,
                slidesToScroll: slidesToScrollMobile || slidesToShowMobile,
              },
            },
          ]}
          className="cards-carousel"
        >
          {cardsEntries.map(entry => (
            <CardsCarouselSlide
              key={entry.id}
              entry={entry}
              CardComponent={CardComponent}
              isMobile={isMobile}
              {...props}
            />
          ))}
        </Carousel>
      )}
      {!!moreLink && !!moreLinkText && (
        <Link className="d-inline-block text-primary-darker mt-1_5" to={moreLink}>
          {moreLinkText}
          <i className="icon-arrow-right4 small ml-0_25" aria-hidden />
        </Link>
      )}
    </Fragment>
  );
}

CardsCarousel.propTypes = {
  content: CmsEntities.Content,
  CardComponent: PropTypes.func.isRequired,
  childrenFilter: PropTypes.func,
  slidesToShowDesktop: PropTypes.number,
  slidesToShowMobile: PropTypes.number,
  slidesToScrollDesktop: PropTypes.number,
  slidesToScrollMobile: PropTypes.number,
  initialSlide: PropTypes.number,
  forceCarousel: PropTypes.bool,
  showArrows: PropTypes.bool,
  centerMode: PropTypes.bool,
  infinite: PropTypes.bool,
  isMobile: PropTypes.bool,
  enableMarketBrewTestBestType: PropTypes.bool,
};

CardsCarousel.defaultProps = {
  content: DEFAULT_CONTENT,
  childrenFilter: null,
  slidesToShowDesktop: 4,
  slidesToShowMobile: 1,
  slidesToScrollDesktop: null,
  slidesToScrollMobile: null,
  initialSlide: null,
  forceCarousel: false,
  showArrows: false,
  centerMode: false,
  infinite: false,
  isMobile: false,
  enableMarketBrewTestBestType: false,
};
