import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { CarNewsArticlesGrid } from 'site-modules/editorial/components/car-news/car-news-articles-grid/car-news-articles-grid';
import { VideoCard } from 'site-modules/editorial/components/car-news/video-card/video-card';
import { NewsCard } from 'site-modules/editorial/components/car-news/news-card/news-card';
import { ImageCard } from 'site-modules/editorial/components/car-news/image-card/image-card';
import { PodcastCard } from 'site-modules/editorial/components/car-news/podcast-card/podcast-card';
import { CardsCarousel } from 'site-modules/editorial/components/car-news/cards-carousel/cards-carousel';
import { EvNewsSection } from 'site-modules/editorial/components/car-news/ev-news-section/ev-news-section';
import { Spotlight } from 'site-modules/editorial/components/car-news/spotlight/spotlight';
import { ArticlesList } from 'site-modules/editorial/components/research-category-landing/research-entry/articles-list';
import { TopRatedPromoUnit } from 'site-modules/shared/components/top-rated-promo-unit/top-rated-promo-unit';

import './car-news-section.scss';

export function renderSectionContent(props) {
  const {
    content: { id },
    suppressTopRatedBanner,
    enableMarketBrewTestBestType,
    isMobile,
  } = props;

  if (id === 'video') {
    return <CardsCarousel {...props} CardComponent={VideoCard} showArrows={!isMobile} centerMode={isMobile} />;
  } else if (id === 'long-terms') {
    return <CardsCarousel {...props} CardComponent={NewsCard} showArrows={!isMobile} centerMode={isMobile} />;
  } else if (id === 'ev-news') {
    return <EvNewsSection {...props} />;
  } else if (id === 'spotlight') {
    return props.content.hasChild('spotlight-1') ? (
      <Spotlight {...props} content={props.content.child('spotlight-1')} />
    ) : null;
  } else if (id === 'best-cars-ranking') {
    return (
      <CardsCarousel
        {...props}
        CardComponent={ImageCard}
        slidesToShowDesktop={6}
        slidesToShowMobile={2}
        enableMarketBrewTestBestType={enableMarketBrewTestBestType}
        showArrows={!isMobile}
        centerMode={isMobile}
      />
    );
  } else if (id === 'car-news-articles') {
    return <ArticlesList {...props} noTitle noMoreLink />;
  } else if (id === 'podcasts') {
    return (
      <CardsCarousel
        {...props}
        CardComponent={PodcastCard}
        forceCarousel
        showArrows={!isMobile}
        centerMode={isMobile}
      />
    );
  } else if (id === 'top-stories') {
    return (
      <Fragment>
        <CarNewsArticlesGrid {...props} />
        {!suppressTopRatedBanner && <TopRatedPromoUnit isMobile={props.isMobile} className="mt-3_5" />}
      </Fragment>
    );
  }

  return <CarNewsArticlesGrid {...props} />;
}

export function CarNewsSection(props) {
  const { content } = props;
  const { title } = content;
  const { anchor } = content.getAllMetadata();

  const children = renderSectionContent(props);

  return children ? (
    <ScrollElement id={anchor || content.id} className={classnames('car-news-section pt-2 pb-3', content.id)}>
      <Container className="px-lg-0">
        {!!title && (
          <div
            className={classnames('d-flex align-items-center mb-1_5', {
              'sr-only': content.id === 'top-stories',
            })}
          >
            <h2 className="section-title text-capitalize heading-3 mb-0">{title}</h2>
          </div>
        )}
        {children}
      </Container>
    </ScrollElement>
  ) : null;
}

CarNewsSection.propTypes = {
  content: CmsEntities.Content,
  // Both of the below props are passed as {...props}
  suppressTopRatedBanner: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  enableMarketBrewTestBestType: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

CarNewsSection.defaultProps = {
  content: DEFAULT_CONTENT,
  suppressTopRatedBanner: false,
  enableMarketBrewTestBestType: false,
};
