import React from 'react';
import { CmsEntities, bindToContent } from 'client/data/models/cms';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { EV_NEWS_WIDGET_CONTENT_PATH } from 'client/site-modules/shared/constants/editorial/editorial';
import { CarNewsArticlesGrid } from 'client/site-modules/editorial/components/car-news/car-news-articles-grid/car-news-articles-grid';

export function EvNewsSectionUI({ content }) {
  return <CarNewsArticlesGrid content={content} totalCardsLimit={3} />;
}

EvNewsSectionUI.propTypes = {
  content: CmsEntities.Content,
};

EvNewsSectionUI.defaultProps = {
  content: DEFAULT_CONTENT,
};

export const EvNewsSection = connectToModel(EvNewsSectionUI, {
  content: bindToContent(EV_NEWS_WIDGET_CONTENT_PATH, 'ev-widget'),
});
