import React from 'react';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';

export function NativeVideoAd(props) {
  return (
    <NativeAd
      adNameWired="sponsoredvideo"
      adNameMobile="msponsoredvideo"
      nativeStyle="sponsoredvideo"
      refreshable={false}
      renderWhenViewable
      verticalOffset="100%"
      {...props}
    />
  );
}
