import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getStaticImageUrl } from 'client/utils/image-helpers';

import './top-rated-ribbon.scss';

const TOP_RATED_BADGE_PATH = {
  blue: '/img/top-rated/badges/badge-white-v1.svg',
  black: '/img/top-rated/badges/badge-gold-v1.svg',
};

const STYLE = {
  padding: '4px 16px 4px 8px',
};

export function TopRatedRibbon({ color, style }) {
  return (
    <div
      className={classnames('top-rated-ribbon-ad pos-r d-inline-block text-white', color)}
      style={{ ...STYLE, ...style }}
    >
      <img width={100} height={12} src={getStaticImageUrl(TOP_RATED_BADGE_PATH[color])} alt="Top Rated" />
    </div>
  );
}

TopRatedRibbon.propTypes = {
  color: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

TopRatedRibbon.defaultProps = {
  style: null,
};
