import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { Image } from 'site-modules/shared/components/image/image';
import { Link } from 'site-modules/shared/components/link/link';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { CarNewsBadge } from 'site-modules/editorial/components/car-news/car-news-badge/car-news-badge';
import { TopRatedRibbon } from 'site-modules/shared/components/top-rated-ribbon/top-rated-ribbon';

import './inline-news-card.scss';

export function InlineNewsCard({ content, isLazyLoadImages }) {
  const isTrending = content.metadata('trendingStory').boolean();
  const authorName = content.metadata('authorName').value() || content.metadata('author').value();
  const articleDate = content.metadata('article-date').value();
  const title = content.metadata('title').value('');
  const promoText = content.metadata('promo-text').value('');
  const articleHref = content.metadata('link-url').value();
  const hasPromoText = promoText && promoText !== '&nbsp;';
  const headerTitle = hasPromoText ? promoText : title;
  const hasTopRatedBadge = content.metadata('topRatedBadge').boolean();

  if (!articleHref) return null;

  return (
    <div className="inline-news-card car-news-card h-100">
      <Link to={articleHref} data-tracking-id="view_content" data-tracking-value={headerTitle}>
        <Row>
          {/* lg-5 is used for critical css */}
          <Col xs={5} lg={5}>
            <div className="card-img-wrapper mw-100 pos-r">
              <Image
                imageId={content.metadata('media-image').value('')}
                source=""
                alt=""
                lazy={isLazyLoadImages}
                className="img-fluid card-image w-100"
              />
              {isTrending && <CarNewsBadge />}
              {hasTopRatedBadge && (
                <TopRatedRibbon color="blue" style={{ position: 'absolute', top: '6px', left: '0' }} />
              )}
            </div>
          </Col>
          {/* lg-7 is used for critical css */}
          <Col xs={7} lg={7}>
            {hasPromoText && (
              <p className="size-12 text-uppercase font-weight-bold text-blue-40 mb-0_5 mt-0_25">{title}</p>
            )}
            <h3 className="article-title heading-5 mb-0_5">
              <ContentFragment>{headerTitle}</ContentFragment>
            </h3>
            <div className="d-flex flex-md-column flex-lg-row align-items-center align-items-md-start justify-content-md-center align-items-lg-center justify-content-lg-start">
              {authorName && (
                <Fragment>
                  <span className="text-gray-darker size-12">{authorName}</span>
                  <span className="d-block d-md-none d-lg-block text-gray-darker mx-0_5">&middot;</span>
                </Fragment>
              )}
              <span className="size-12 text-cool-gray-40">{articleDate}</span>
            </div>
          </Col>
        </Row>
      </Link>
    </div>
  );
}

InlineNewsCard.propTypes = {
  content: CmsEntities.Content,
  isLazyLoadImages: PropTypes.bool,
};

InlineNewsCard.defaultProps = {
  content: DEFAULT_CONTENT,
  isLazyLoadImages: false,
};
