import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './podcast-preview.scss';

export function PodcastPreview({
  title,
  image,
  disabled,
  className,
  imgClassName,
  iframeCode,
  openIframeModal,
  isLazyLoadImages,
}) {
  function onModalOpen() {
    openIframeModal({ iframeCode });
  }

  return (
    <div className={classnames('podcast-preview w-100 pos-r', className)}>
      <img
        className={classnames('preview-img w-100', imgClassName)}
        src={image}
        alt={title}
        loading={isLazyLoadImages ? 'lazy' : undefined}
      />
      <button disabled={disabled} aria-label="Play" className="loader w-100 h-100 p-0 border-0" onClick={onModalOpen} />
    </div>
  );
}

PodcastPreview.propTypes = {
  openIframeModal: PropTypes.func.isRequired,
  iframeCode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  isLazyLoadImages: PropTypes.bool,
};

PodcastPreview.defaultProps = {
  disabled: false,
  className: '',
  imgClassName: '',
  isLazyLoadImages: false,
};
