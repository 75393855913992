import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getYoutubePreviewImage, YOUTUBE_PREVIEW_SIZE } from 'client/utils/image-helpers';

import './video-preview.scss';

export function VideoPreview({
  videoId,
  videoTitle,
  videoThumbnailUrl,
  videoAspectRatio,
  openVideoModal,
  creativeId,
  isStaticPos,
  isAutoHeight,
  disabled,
  isWhiteBtn,
  imgClassName,
  isLazyLoadImages,
}) {
  function openVideo() {
    openVideoModal({
      creativeId,
      videoId,
      videoTitle,
      videoAspectRatio,
    });
  }

  return (
    <div className={classnames('video-preview w-100 pos-r', { 'h-100': !isAutoHeight, 'white-btn': isWhiteBtn })}>
      <div className={classnames('preview-image', { 'static-pos': isStaticPos })}>
        <img
          className={classnames('placeholder-img w-100', imgClassName)}
          src={videoThumbnailUrl || getYoutubePreviewImage(videoId, YOUTUBE_PREVIEW_SIZE.HQ)}
          alt={videoTitle}
          loading={isLazyLoadImages ? 'lazy' : undefined}
        />
      </div>
      <button aria-label="Play" className="loader w-100 h-100 p-0 border-0" disabled={disabled} onClick={openVideo} />
    </div>
  );
}

VideoPreview.propTypes = {
  openVideoModal: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  creativeId: PropTypes.string.isRequired,
  videoThumbnailUrl: PropTypes.string,
  videoAspectRatio: PropTypes.string,
  isStaticPos: PropTypes.bool,
  isAutoHeight: PropTypes.bool,
  isWhiteBtn: PropTypes.bool,
  disabled: PropTypes.bool,
  isLazyLoadImages: PropTypes.bool,
  imgClassName: PropTypes.string,
};

VideoPreview.defaultProps = {
  videoAspectRatio: '16:9',
  videoThumbnailUrl: null,
  isStaticPos: false,
  isAutoHeight: false,
  isWhiteBtn: false,
  disabled: false,
  isLazyLoadImages: false,
  imgClassName: '',
};
